var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        "z-index": 8,
        closable: true,
        visible: _vm.addRecord,
        title: _vm.type === "add" ? "添加补卡" : _vm.flag ? "编辑补卡" : "补卡",
        size: "normal",
      },
      on: { cancel: _vm.onClose, ok: _vm.onSubmin },
    },
    [
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "员工姓名", prop: "onJobId" } },
            [
              _c("PerTreeSelect", {
                attrs: {
                  placeholder: "请选择员工",
                  disabled: _vm.type === "edit",
                },
                on: { getUserInfo: _vm.getUserInfo },
                model: {
                  value: _vm.form.onJobId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "onJobId", $$v)
                  },
                  expression: "form.onJobId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "补卡日期", prop: "punchDate" } },
            [
              _c("a-date-picker", {
                attrs: {
                  disabled: _vm.flag,
                  "value-format": "YYYY-MM-DD",
                  "disabled-date": _vm.disabledDate,
                  format: "YYYY-MM-DD",
                },
                on: {
                  change: function ($event) {
                    ;(_vm.form.punchTime = ""), _vm.getPunchList()
                  },
                },
                model: {
                  value: _vm.form.punchDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "punchDate", $$v)
                  },
                  expression: "form.punchDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "补卡时间", prop: "punchTime" } },
            [
              _c("a-time-picker", {
                attrs: {
                  "disabled-hours": _vm.disabledHours,
                  "disabled-minutes": _vm.disabledMinutes,
                  "default-open-value": _vm.moment("00:00", "HH:mm"),
                  "value-format": "HH:mm",
                  format: "HH:mm",
                },
                on: { openChange: _vm.getTime },
                model: {
                  value: _vm.form.punchTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "punchTime", $$v)
                  },
                  expression: "form.punchTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.punchList.length > 1,
                  expression: "punchList.length>1",
                },
              ],
              attrs: { label: "归属时段" },
            },
            [
              _c(
                "a-radio-group",
                {
                  on: {
                    change: function ($event) {
                      return _vm.planchange(_vm.val)
                    },
                  },
                  model: {
                    value: _vm.form.staffShiftPunchId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "staffShiftPunchId", $$v)
                    },
                    expression: "form.staffShiftPunchId",
                  },
                },
                _vm._l(_vm.punchList, function (item, index) {
                  return _c(
                    "a-radio",
                    {
                      key: index,
                      attrs: {
                        value: item.dayType === 1 ? item.staffShiftPunchId : "",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.dayType === 1
                              ? item.planInTime + "-" + item.planOutTime
                              : item.dayType === 2
                              ? "休息日"
                              : "节假日"
                          ) +
                          " "
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }